import React, { memo } from 'react';
import {
	ApiCallActionIcon,
	ApiOutlined,
	CodeOutlined,
	ForkOutlined,
	GptActionIcon,
	JSONataCallActionIcon,
	LlmActionIcon,
	MailingCallActionIcon,
	SyncOutlined,
	ToolFilled,
	VaultActionIcon,
	PlaySquareOutlined
} from 'shared/ui/Icons';
import { CustomCollapse } from 'shared/ui';
import { TBlockTypes } from '../../model/types';
import { useTranslate } from 'shared/lib/i18n';

interface IActionsList {
	handleSelectAction: (value: TBlockTypes) => void;
	isMapCallItem?: boolean;
}

const ActionsListComponent = ({ handleSelectAction, isMapCallItem }: IActionsList) => {
	const t = useTranslate();

	return (
		<div className='min-w-[220px] pt-3'>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleSelectAction('gpt_call');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
					<GptActionIcon />
				</div>
				<span>{t('gpt_call')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleSelectAction('api_template_call');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
					<ApiOutlined className='text-blue-800' />
				</div>
				<span>{t('api_template_call')}</span>
			</div>
			<div
				onClick={(e) => {
					e.preventDefault();
					handleSelectAction('mailing_call');
				}}
				className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
			>
				<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
					<MailingCallActionIcon />
				</div>
				<span>{t('mailing_call')}</span>
			</div>
			{!isMapCallItem && (
				<div
					onClick={(e) => {
						e.preventDefault();
						handleSelectAction('vault_call');
					}}
					className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
				>
					<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
						<VaultActionIcon />
					</div>
					<span>{t('vault_call')}</span>
				</div>
			)}
			<CustomCollapse
				className='mt-3'
				expandIconPosition={'end'}
				ghost
				items={[
					{
						label: <span className='ml-3 font-medium'>{t('advanced')}</span>,
						children: (
							<>
								<div
									onClick={(e) => {
										e.preventDefault();
										handleSelectAction('api_call');
									}}
									className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
								>
									<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
										<ApiCallActionIcon />
									</div>
									<span>{t('api_call')}</span>
								</div>
								<div
									onClick={(e) => {
										e.preventDefault();
										handleSelectAction('jsonata_call');
									}}
									className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
								>
									<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
										<JSONataCallActionIcon />
									</div>
									<span>JSONata</span>
								</div>
								<div
									onClick={(e) => {
										e.preventDefault();
										handleSelectAction('llm_call');
									}}
									className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
								>
									<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
										<LlmActionIcon />
									</div>
									<span>LLM</span>
								</div>
								{!isMapCallItem && (
									<>
										<div
											onClick={(e) => {
												e.preventDefault();
												handleSelectAction('task_call');
											}}
											className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
										>
											<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
												<ToolFilled className='text-cyan-600' />
											</div>
											<span>{t('task_call')}</span>
										</div>
										<div
											onClick={(e) => {
												e.preventDefault();
												handleSelectAction('if_call');
											}}
											className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
										>
											<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
												<ForkOutlined className='text-green-500' />
											</div>
											<span>{t('condition')}</span>
										</div>
										<div
											onClick={(e) => {
												e.preventDefault();
												handleSelectAction('map_call');
											}}
											className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
										>
											<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
												<SyncOutlined className='text-red-500' />
											</div>
											<span>{t('loop')}</span>
										</div>
									</>
								)}
								<div
									onClick={(e) => {
										e.preventDefault();
										handleSelectAction('user_input');
									}}
									className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
								>
									<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
										<CodeOutlined className='text-yellow-500' />
									</div>
									<span>{t('user_input')}</span>
								</div>
								<div
									onClick={(e) => {
										e.preventDefault();
										handleSelectAction('app_run_step');
									}}
									className='w-full  flex gap-x-2 items-center px-3 py-1.5 hover:bg-fill-tertiary rounded cursor-pointer'
								>
									<div className='bg-fill-tertiary rounded min-w-[24px] min-h-[24px] flex items-center justify-center'>
										<PlaySquareOutlined className='text-primary-active' />
									</div>
									<span>{t('app_launch')}</span>
								</div>
							</>
						)
					}
				]}
			/>
		</div>
	);
};

export const ActionsList = memo(ActionsListComponent);
