import React, { memo } from 'react';
import { Position, NodeProps } from 'reactflow';
import cn from 'classnames';
import LimitedHandle from '../CustomHandle';
import { ForkOutlined } from 'shared/ui/Icons';
import { Divider, Typography } from 'antd';
import { useTranslate } from 'shared/lib/i18n';
import { NodeActions } from '../NodeActions';
import { ENodeHandleVariants } from '../../model/types';

const IfCallActionComponent = (props: NodeProps) => {
	const t = useTranslate();
	const { data, selected } = props;
	return (
		<div
			className={cn(
				'p-3 pb-6 rounded-md bg-white border border-solid border-gray-border min-w-[168px] max-w-[168px] min-h-[14px] relative',
				{
					'border-primary-active border-2': selected,
					'!border-error border-2': data.status === 'error'
				}
			)}
		>
			<div>
				<div className='flex gap-x-2 items-center'>
					<div className='bg-fill-tertiary rounded min-w-[20px] min-h-[20px] flex items-center justify-center'>
						<ForkOutlined className='text-green-500' />
					</div>
					<Typography.Paragraph
						rootClassName='text-sm font-medium text-black !m-0 !line-clamp-1'
						ellipsis={{
							rows: 2,
							expanded: false
						}}
					>
						{t('condition')}
					</Typography.Paragraph>
					<div className='ml-auto'>{!!data.slug && !data.isMapCallItem && <NodeActions currentSlug={data.slug} />}</div>
				</div>
				<Divider style={{ margin: '8px 0' }} />
				<div className='text-primary-font px-2 py-0.5 rounded-[33px] text-xs leading-5 bg-primary-bg-hover max-w-[60px] min-w-[60px] text-center overflow-hidden text-ellipsis whitespace-nowrap'>
					{data.slug}
				</div>
			</div>
			<LimitedHandle
				type='target'
				position={data.isMapCallItem ? Position.Left : Position.Top}
				className='opacity-0'
				id={ENodeHandleVariants.SOURCE_HANDLE}
				isConnectable={true}
			/>
			<LimitedHandle
				type='source'
				id={ENodeHandleVariants.SUCCESS}
				position={Position.Bottom}
				className='!rounded-full !border-solid !border-primary !border-2 !w-2 !h-2 !bg-white !-bottom-[5px] !left-[20px]'
				isConnectable={typeof data.nextSlug === 'object' && !data.nextSlug[ENodeHandleVariants.SUCCESS]}
			>
				<div className='absolute bottom-1.5 left-1/2 -translate-x-1/2 text-xs lowercase'>{t('yes')}</div>
			</LimitedHandle>
			<LimitedHandle
				type='source'
				id={ENodeHandleVariants.FAIL}
				position={Position.Bottom}
				className='!rounded-full !border-solid !border-primary !border-2 !w-2 !h-2 !bg-white !-bottom-[5px] !left-auto !right-[20px]'
				isConnectable={typeof data.nextSlug === 'object' && !data.nextSlug[ENodeHandleVariants.FAIL]}
			>
				<div className='absolute bottom-1.5 left-1/2 -translate-x-1/2 text-xs lowercase'>{t('no')}</div>
			</LimitedHandle>
		</div>
	);
};

export const IfCallAction = memo(IfCallActionComponent);
